import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

const Cta = () => {
	return (
		<div className="cta-container">
			<Container className="flex-column">
				<div className="cta-content flex-column">
					<h2 className="cta-title title-xl text-regular-lexend align-center mb-15">
						Get Started with a Free Virtual Number!
					</h2>
					<p className="cta-desc text-lg opacity-5 align-center mb-30">
						Upgrade your plan today and get 1 FREE virtual mobile number that
						you can use for OTPs.
					</p>
					<a
						href="https://dashboard.smsafe.app/signup"
						className="full align-center"
					>
						<Button className="cta-btn btn-default text-xl mb-20">
							Sign Up Now
						</Button>
					</a>
					<span className="see-pricing opacity-5">
						or{" "}
						<a href="/pricing" className="text-medium-inter">
							See Pricing
						</a>
					</span>
				</div>
			</Container>
		</div>
	);
};

export default Cta;
