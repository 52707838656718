import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const Platforms = () => {
	const socialMedia = [
		{ name: "Facebook", icon: "", sms: true, totp: true },
		{ name: "Instagram", icon: "", sms: true, totp: true },
		{ name: "Tiktok", icon: "", sms: true, totp: true },
		{ name: "X", icon: "", sms: true, totp: true },
		{ name: "Youtube", icon: "", sms: true, totp: true },
		{ name: "Discord", icon: "", sms: false, totp: true },
		{ name: "Pinterest", icon: "", sms: true, totp: true },
		{ name: "LinkedIn", icon: "", sms: true, totp: true },
	];

	const finance = [
		{ name: "Braintree", icon: "", sms: true, totp: true },
		{ name: "Stripe", icon: "", sms: true, totp: true },
		{ name: "Paypal", icon: "", sms: true, totp: true },
	];

	const crypto = [
		{ name: "Binance", icon: "", sms: true, totp: true },
		{ name: "Coinbase", icon: "", sms: true, totp: true },
	];

	const domain = [
		{ name: "Namecheap", icon: "", sms: false, totp: true },
		{ name: "GoDaddy", icon: "", sms: true, totp: true },
		{ name: "Domain.com", icon: "", sms: false, totp: true },
	];

	const builders = [
		{ name: "Wix", icon: "", sms: true, totp: true },
		{ name: "Squarespace", icon: "", sms: true, totp: true },
		{ name: "Shopify", icon: "", sms: true, totp: true },
	];

	const authentication = [
		{ name: "Auth0", icon: "", sms: false, totp: true },
		{ name: "Supabase", icon: "", sms: false, totp: true },
	];

	const cloud = [
		{ name: "Cloudflare", icon: "", sms: false, totp: true },
		{ name: "Azure", icon: "", sms: false, totp: true },
		{ name: "Google", icon: "", sms: true, totp: true },
		{ name: "AWS", icon: "", sms: false, totp: true },
	];

	return (
		<div className="content">
			<div className="page-banner bg-default text-white">
				<Container className="flex-column align-center">
					<h1 className="title-xl text-regular-lexend mb-30">
						Supported Platforms
					</h1>
					<p className="text-lg opacity-7 mb-30">
						SMSafe is designed to work with every platform that supports SMS
						OTPs and token-based 2FA.
					</p>
					<a href="https://dashboard.smsafe.app/signup">
						<Button className="btn-wide btn-lg btn-default text-md mb-15 rounded-4">
							Get Started
						</Button>
					</a>
					<span className="opacity-5 text-white mb-50">
						or{" "}
						<a href="/pricing" className="text-medium-inter text-white">
							See Pricing
						</a>
					</span>
				</Container>
			</div>
			<div className="feature-page-content">
				<Container>
					<Row>
						<Col className="col-6">
							<h2 className="platform-label text-lg text-medium-inter">
								Social Media
							</h2>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								SMS OTP
							</span>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								TOTP
							</span>
						</Col>
					</Row>
					<div className="platform-category mb-50">
						{socialMedia
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((platform) => (
								<Row className="flex-row-left platform-item">
									<Col className="col-6 flex-row-left">
										<span className="platform-name">{platform.name}</span>
									</Col>
									<Col className="col-3">
										{platform.sms && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
									<Col className="col-3">
										{platform.totp && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
								</Row>
							))}
					</div>
					<Row>
						<Col className="col-6">
							<h2 className="platform-label text-lg text-medium-inter">
								Finance
							</h2>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								SMS OTP
							</span>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								TOTP
							</span>
						</Col>
					</Row>
					<div className="platform-category mb-50">
						{finance
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((platform) => (
								<Row className="flex-row-left platform-item">
									<Col className="col-6 flex-row-left">
										<span className="platform-name">{platform.name}</span>
									</Col>
									<Col className="col-3">
										{platform.sms && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
									<Col className="col-3">
										{platform.totp && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
								</Row>
							))}
					</div>
					<Row>
						<Col className="col-6">
							<h2 className="platform-label text-lg text-medium-inter">
								Cryptocurrency
							</h2>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								SMS OTP
							</span>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								TOTP
							</span>
						</Col>
					</Row>
					<div className="platform-category mb-50">
						{crypto
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((platform) => (
								<Row className="flex-row-left platform-item">
									<Col className="col-6 flex-row-left">
										<span className="platform-name">{platform.name}</span>
									</Col>
									<Col className="col-3">
										{platform.sms && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
									<Col className="col-3">
										{platform.totp && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
								</Row>
							))}
					</div>
					<Row>
						<Col className="col-6">
							<h2 className="platform-label text-lg text-medium-inter">
								Cloud Services
							</h2>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								SMS OTP
							</span>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								TOTP
							</span>
						</Col>
					</Row>
					<div className="platform-category mb-50">
						{cloud
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((platform) => (
								<Row className="flex-row-left platform-item">
									<Col className="col-6 flex-row-left">
										<span className="platform-name">{platform.name}</span>
									</Col>
									<Col className="col-3">
										{platform.sms && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
									<Col className="col-3">
										{platform.totp && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
								</Row>
							))}
					</div>
					<Row>
						<Col className="col-6">
							<h2 className="platform-label text-lg text-medium-inter">
								Domain Management
							</h2>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								SMS OTP
							</span>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								TOTP
							</span>
						</Col>
					</Row>
					<div className="platform-category mb-50">
						{domain
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((platform) => (
								<Row className="flex-row-left platform-item">
									<Col className="col-6 flex-row-left">
										<span className="platform-name">{platform.name}</span>
									</Col>
									<Col className="col-3">
										{platform.sms && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
									<Col className="col-3">
										{platform.totp && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
								</Row>
							))}
					</div>
					<Row>
						<Col className="col-6">
							<h2 className="platform-label text-lg text-medium-inter">
								Website Builders
							</h2>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								SMS OTP
							</span>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								TOTP
							</span>
						</Col>
					</Row>
					<div className="platform-category mb-50">
						{builders
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((platform) => (
								<Row className="flex-row-left platform-item">
									<Col className="col-6 flex-row-left">
										<span className="platform-name">{platform.name}</span>
									</Col>
									<Col className="col-3">
										{platform.sms && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
									<Col className="col-3">
										{platform.totp && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
								</Row>
							))}
					</div>
					<Row>
						<Col className="col-6">
							<h2 className="platform-label text-lg text-medium-inter">
								Authentication
							</h2>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								SMS OTP
							</span>
						</Col>
						<Col className="col-3">
							<span className="text-medium-inter platform-label text-sm">
								TOTP
							</span>
						</Col>
					</Row>
					<div className="platform-category mb-50">
						{authentication
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((platform) => (
								<Row className="flex-row-left platform-item">
									<Col className="col-6 flex-row-left">
										<span className="platform-name">{platform.name}</span>
									</Col>
									<Col className="col-3">
										{platform.sms && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
									<Col className="col-3">
										{platform.totp && (
											<i className="ri-check-line text-success text-xl" />
										)}
									</Col>
								</Row>
							))}
					</div>
				</Container>
			</div>
		</div>
	);
};

export default Platforms;
