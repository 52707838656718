import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LogoLight from "./LogoLight";

const Footer = () => {
	return (
		<div className="footer bg-default full">
			<Container>
				<Row>
					<Col className="footer-logo-container col-lg-6 col-12 flex-row-left">
						<LogoLight className="footer-logo" />
						<span className="copyright text-sm text-white opacity-5">
							SMSafe &copy; {new Date().getFullYear()}. All rights reserverd
						</span>
					</Col>
					<Col className="footer-links-container col-lg-6 col-12 flex-row-right">
						<a href="https://linkedin.com" className="footer-link text-white">
							LinkedIn
						</a>
						<a href="https://twitter.com" className="footer-link text-white">
							Twitter
						</a>
						<a href="/privacy" className="footer-link text-white">
							Privacy
						</a>
						<a href="/privacy" className="footer-link text-white">
							Terms
						</a>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Footer;
