import { useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Contact = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [actionLoading, setActionLoading] = useState(false);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const sendMessage = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			body: JSON.stringify({
				name: name,
				email: email,
				message: message,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/support/message`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setName("");
			setEmail("");
			setMessage("");
			defaultSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	return (
		<div className="content">
			<div className="contact-banner page-banner">
				<Container className="flex-column">
					<Form onSubmit={sendMessage} className="col-md-4">
						<h1 className="contact-title title-md text-medium-lexend mb-30 align-center">
							Reach out, we're just a form away!
						</h1>
						<Row className="mb-30">
							<Col>
								<Form.Label>Name</Form.Label>
								<Form.Control
									required
									type="text"
									className="input-lg text-md rounded-8"
									placeholder="Enter your name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-30">
							<Col>
								<Form.Label>Email</Form.Label>
								<Form.Control
									required
									type="email"
									className="input-lg text-md rounded-8"
									placeholder="Enter your email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-30">
							<Col>
								<Form.Label>Message</Form.Label>
								<Form.Control
									required
									as="textarea"
									className="contact-content text-md rounded-8"
									placeholder="Your message.."
									value={message}
									onChange={(e) => setMessage(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="mb-30">
							<Col>
								<Button
									type="submit"
									className="btn-default btn-lg text-md full rounded-4"
									disabled={actionLoading}
								>
									{actionLoading ? "Sending.." : "Send"}
								</Button>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
		</div>
	);
};

export default Contact;
