import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import ContactCta from "../components/ContactCta";

const Faq = () => {
	return (
		<div className="content">
			<div className="faq-banner page-banner">
				<Container>
					<Row>
						<Col className="col-lg-5 col-12">
							<h2 className="faq-title title-xl text-regular-lexend mb-30">
								Frequently Asked Questions
							</h2>
						</Col>
						<Col className="col-lg-7 col-12">
							<Accordion>
								<Accordion.Item eventKey="0">
									<Accordion.Header className="text-lg opacity-5">
										How does SMSafe ensure the privacy of my personal
										information?
									</Accordion.Header>
									<Accordion.Body className="text-sm">
										SMSafe prioritizes the security and privacy of your personal
										information. We employ industry-standard security measures,
										including encryption, secure data storage, and access
										controls, to protect against unauthorized access,
										disclosure, alteration, and destruction of your data.
										Additionally, SMSafe is designed to adhere to strict privacy
										guidelines outlined in our{" "}
										<a href="/privacy" className="decorated">
											Privacy and Terms of Service Statement
										</a>
										, providing you with a secure environment for your personal
										information.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header className="text-lg opacity-5">
										Can I use SMSafe for both personal and business accounts?
									</Accordion.Header>
									<Accordion.Body className="text-sm">
										Yes, SMSafe is designed to be versatile and can be used for
										both personal and business accounts. Whether you're securing
										your personal online presence or implementing two-factor
										authentication for your business, SMSafe provides a reliable
										and secure solution for a wide range of use cases.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header className="text-lg opacity-5">
										Are the virtual phone numbers provided by SMSafe globally
										accessible?
									</Accordion.Header>
									<Accordion.Body className="text-sm">
										No, virtual phone numbers provided by SMSafe are specific to
										the country of origin, as determined by their country code.
										They can only be used within the same country as the
										assigned country code.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header className="text-lg opacity-5">
										Can I use SMSafe with popular authentication apps like
										Google Authenticator?
									</Accordion.Header>
									<Accordion.Body className="text-sm">
										Yes, SMSafe is compatible with popular authentication apps
										like Google Authenticator. You can use SMSafe to generate
										and store time-based one-time passcodes (TOTP) alongside
										other authentication methods, providing you with flexibility
										and convenience in securing your accounts.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header className="text-lg opacity-5">
										What happens if I lose my phone or switch devices?
									</Accordion.Header>
									<Accordion.Body className="text-sm">
										If you lose your phone or switch devices, worry not. SMSafe
										is cloud-based, allowing users to retain access to their
										virtual mobile numbers and two-factor authentication (2FA)
										settings. Simply log in to your SMSafe account on the new
										device, and your virtual numbers and 2FAs will be
										accessible, ensuring a seamless transition without
										compromising security.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="5">
									<Accordion.Header className="text-lg opacity-5">
										Is SMSafe compatible with popular online platforms and
										services?
									</Accordion.Header>
									<Accordion.Body className="text-sm">
										Yes, SMSafe is compatible with a wide range of popular
										online platforms and services. Whether you're securing your
										social media accounts, email accounts, or other online
										services, SMSafe provides a reliable two-factor
										authentication solution to enhance the security of your
										accounts across various platforms.
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Col>
					</Row>
				</Container>
			</div>
			<ContactCta />
		</div>
	);
};

export default Faq;
