import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SubFeature from "../components/SubFeature";
import FaqPreview from "../components/FaqPreview";
import Cta from "../components/Cta";
import ContactCta from "../components/ContactCta";

import safe from "../assets/safe.png";
import msisdn from "../assets/msisdn.png";
import verify from "../assets/verify.png";
import mfa from "../assets/mfa.png";
import secure from "../assets/secure.png";
import PlatformPreview from "../components/PlatformPreview";
import Transition from "../components/Transition";

const Home = () => {
	return (
		<>
			<div className="banner bg-default">
				<Container>
					<div className="flex-column banner-content">
						<h1 className="banner-title title-xxl text-white text-regular-lexend mb-30">
							Securely Manage Your MFA with SMSafe
						</h1>
						<p className="banner-desc text-xl text-white opacity-7 mb-30">
							Dedicated SMS numbers and 2FA sharing for Seamless and Secure
							Collaboration
						</p>
						<a
							href="https://dashboard.smsafe.app/signup"
							className="full align-center"
						>
							<Button className="btn-default banner-btn btn-lg rounded-4 text-md mb-15">
								Get Started
							</Button>
						</a>
						<span className="text-white text-sm">
							or{" "}
							<a href="/pricing" className="text-medium-inter text-white">
								See Pricing
							</a>
						</span>
					</div>
				</Container>
				<div className="banner-screen-container flex-column full">
					<div
						className="banner-screen"
						style={{
							background: `url(${safe})`,
						}}
					>
						{/* <div className="sub-screen-1"></div>
						<div className="sub-screen-2"></div>
						<div className="sub-screen-3"></div>
						<div className="sub-screen-4"></div> */}
					</div>
				</div>
			</div>
			<div className="features">
				<div className="feature">
					<Container>
						<Row className="flex-row-center">
							<Col className="col-lg-6 col-12 flex-row-right">
								<div className="feature-img-container full">
									<div className="feature-img left flex-column">
										<img
											src={msisdn}
											alt="Virtual Mobile Number"
											className="msisdn-img"
										/>
									</div>
								</div>
							</Col>
							<Col className="col-lg-6 col-12">
								<div className="feature-content p-30">
									<h2 className="feature-title title-xl text-regular-lexend mb-15">
										Virtual Number Provision
									</h2>
									<p className="feature-desc text-xl opacity-5 mb-30">
										Get Your Dedicated Phone Number for 2FA
									</p>
									<SubFeature content="Eliminate reliance on personal phone numbers for 2FA" />
									<SubFeature content="Keep personal and professional communications separate" />
									<SubFeature
										className="mb-30"
										content="Increase security and reduce the risk of SIM swapping or
												phone number hijacking"
									/>
									<a href="https://dashboard.smsafe.app/signup">
										<Button className="feature-btn btn-wide btn-default-outlined rounded-4 btn-lg text-md">
											Get Started
										</Button>
									</a>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="feature">
					<Container>
						<Row className="flex-row-center">
							<Col className="feature-sm col-lg-6 col-12 flex-row-right">
								<div className="feature-img-container full">
									<div className="feature-img left flex-column">
										<img
											src={verify}
											alt="Verified Providers"
											className="verify-img"
										/>
									</div>
								</div>
							</Col>
							<Col className="col-lg-6 col-12">
								<div className="feature-content p-30">
									<h2 className="feature-title title-xl text-regular-lexend mb-15">
										Verify the Providers
									</h2>
									<p className="feature-desc text-xl opacity-5 mb-30">
										Trustworthy and Reliable Verification Services
									</p>
									<SubFeature
										content="Validate the authenticity and legitimacy of service
												providers"
									/>
									<SubFeature
										className="mb-30"
										content="Protect against phishing and spoofing attempts"
									/>
									<a href="https://dashboard.smsafe.app/signup">
										<Button className="feature-btn btn-wide btn-default-outlined rounded-4 btn-lg text-md">
											Get Started
										</Button>
									</a>
								</div>
							</Col>
							<Col className="feature-lg col-lg-6 col-12 flex-row-left">
								<div className="feature-img-container full">
									<div className="feature-img right flex-column">
										<img
											src={verify}
											alt="Verified Providers"
											className="verify-img"
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="feature">
					<Container>
						<Row className="flex-row-center">
							<Col className="col-lg-6 col-12 flex-row-right">
								<div className="feature-img-container full">
									<div className="feature-img left flex-column">
										<img src={mfa} alt="2FA Management" className="mfa-img" />
									</div>
								</div>
							</Col>
							<Col className="col-lg-6 col-12">
								<div className="feature-content p-30">
									<h2 className="feature-title title-xl text-regular-lexend mb-15">
										Easily Manage 2FA
									</h2>
									<p className="feature-desc text-xl opacity-5 mb-30">
										Simplified 2FA Management at Your Fingertips
									</p>
									<SubFeature
										content="Centralize and manage all your 2FA codes in one secure
												app"
									/>
									<SubFeature
										className="mb-30"
										content="Securely backup and restore your 2FA accounts across
										devices"
									/>
									<a href="https://dashboard.smsafe.app/signup">
										<Button className="feature-btn btn-wide btn-default-outlined rounded-4 btn-lg text-md">
											Get Started
										</Button>
									</a>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="feature mb-50">
					<Container>
						<Row className="flex-row-center">
							<Col className="feature-sm col-lg-6 col-12 flex-row-right">
								<div className="feature-img-container full">
									<div className="feature-img left flex-column">
										<img
											src={secure}
											alt="Enhance Your Online Security"
											className="secure-img"
										/>
									</div>
								</div>
							</Col>
							<Col className="col-lg-6 col-12">
								<div className="feature-content p-30">
									<h2 className="feature-title title-xl text-regular-lexend mb-15">
										Protect Yourself
									</h2>
									<p className="feature-desc text-xl opacity-5 mb-30">
										Enhance Your Online Security
									</p>
									<SubFeature
										content="Safeguard sensitive information and prevent unauthorized
												access"
									/>
									<SubFeature
										content="Stay one step ahead with an additional layer of
												verification"
									/>
									<SubFeature
										className="mb-30"
										content="Empower yourself with the latest security measures to
												defend against cyber threats"
									/>
									<a href="https://dashboard.smsafe.app/signup">
										<Button className="feature-btn btn-wide btn-default-outlined rounded-4 btn-lg text-md">
											Get Started
										</Button>
									</a>
								</div>
							</Col>
							<Col className="feature-lg col-lg-6 col-12 flex-row-left">
								<div className="feature-img-container full">
									<div className="feature-img right flex-column">
										<img
											src={secure}
											alt="Enhance Your Online Security"
											className="secure-img"
										/>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
			<div className="feature-summary-container">
				<Container>
					<div className="feature-summary bg-default">
						<Row className="flex-row-left mb-50">
							<Col className="col-lg-8 col-12">
								<h2 className="feature-summary-title title-xl text-white text-regular-lexend">
									Every Secure Feature You Need
								</h2>
							</Col>
							<Col className="col-lg-4 col-12 summary-btn-container flex-row-right">
								<Link to="/features" className="feature-summary-btn">
									<Button className="full btn-default btn-lg text-md rounded-4">
										Read More
									</Button>
								</Link>
							</Col>
						</Row>
						<Row>
							<Col className="feature-summary-item col-lg-4 col-md-6 col-12 mb-50">
								<div className="flex-row-left mb-10">
									<i className="feature-summary-icon text-xl text-white ri-smartphone-line"></i>
									<h4 className="feature-summary-label text-lg text-white text-medium-inter opacity-7 mb-0">
										Virtual Phone Number Provision
									</h4>
								</div>
								<p className="feature-summary-desc text-white opacity-5 mb-0">
									Multiple phone number options for different services/accounts
								</p>
							</Col>
							<Col className="feature-summary-item col-lg-4 col-md-6 col-12 mb-50">
								<div className="flex-row-left mb-10">
									<i className="feature-summary-icon text-xl text-white ri-database-line"></i>
									<h4 className="feature-summary-label text-lg text-white text-medium-inter opacity-7 mb-0">
										2FA Code Management
									</h4>
								</div>
								<p className="feature-summary-desc text-white opacity-5 mb-0">
									Centralized storage and management of 2FA codes
								</p>
							</Col>
							<Col className="feature-summary-item col-lg-4 col-md-6 col-12 mb-50">
								<div className="flex-row-left mb-10">
									<i className="feature-summary-icon text-xl text-white ri-lock-2-line"></i>
									<h4 className="feature-summary-label text-lg text-white text-medium-inter opacity-7 mb-0">
										Secure Authentication
									</h4>
								</div>
								<p className="feature-summary-desc text-white opacity-5 mb-0">
									Protection against unauthorized access and identity theft
								</p>
							</Col>
							<Col className="feature-summary-item col-lg-4 col-md-6 col-12 mb-50">
								<div className="flex-row-left mb-10">
									<i className="feature-summary-icon text-xl text-white ri-settings-line"></i>
									<h4 className="feature-summary-label text-lg text-white text-medium-inter opacity-7 mb-0">
										Verification Services
									</h4>
								</div>
								<p className="feature-summary-desc text-white opacity-5 mb-0">
									Verification of service providers' authenticity and
									trustworthiness
								</p>
							</Col>
							<Col className="feature-summary-item col-lg-4 col-md-6 col-12 mb-50">
								<div className="flex-row-left mb-10">
									<i className="feature-summary-icon text-xl text-white ri-device-line"></i>
									<h4 className="feature-summary-label text-lg text-white text-medium-inter opacity-7 mb-0">
										Multi-Platform Support
									</h4>
								</div>
								<p className="feature-summary-desc text-white opacity-5 mb-0">
									Seamless integration with social media, email, banking, and
									more
								</p>
							</Col>
							<Col className="feature-summary-item col-lg-4 col-md-6 col-12 mb-50">
								<div className="flex-row-left mb-10">
									<i className="feature-summary-icon text-xl text-white ri-device-recover-line"></i>
									<h4 className="feature-summary-label text-lg text-white text-medium-inter opacity-7 mb-0">
										Account Recovery
									</h4>
								</div>
								<p className="feature-summary-desc text-white opacity-5 mb-0">
									Account recovery options in case of device loss or change
								</p>
							</Col>
							<Col className="feature-summary-item col-lg-4 col-md-6 col-12 mb-50">
								<div className="flex-row-left mb-10">
									<i className="feature-summary-icon text-xl text-white ri-chat-private-line"></i>
									<h4 className="feature-summary-label text-lg text-white text-medium-inter opacity-7 mb-0">
										Privacy and Data Security
									</h4>
								</div>
								<p className="feature-summary-desc text-white opacity-5 mb-0">
									Knowledge base and FAQ section for self-help
								</p>
							</Col>
							<Col className="feature-summary-item col-lg-4 col-md-6 col-12 mb-50">
								<div className="flex-row-left mb-10">
									<i className="feature-summary-icon text-xl text-white ri-headphone-line"></i>
									<h4 className="feature-summary-label text-lg text-white text-medium-inter opacity-7 mb-0">
										Customer Support
									</h4>
								</div>
								<p className="feature-summary-desc text-white opacity-5 mb-0">
									Knowledge base and FAQ section for self-help
								</p>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
			<PlatformPreview />
			<Transition />
			<FaqPreview />
			<Cta />
			<ContactCta />
		</>
	);
};

export default Home;
