import { React } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "remixicon/fonts/remixicon.css";
import "./index.css";

import ScrollToTop from "./components/ScrollToTop";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Platforms from "./pages/Platforms";

const root = createRoot(document.getElementById("root"));

export default function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/*" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="features" element={<Features />} />
					<Route path="pricing" element={<Pricing />} />
					<Route path="faq" element={<Faq />} />
					<Route path="contact" element={<Contact />} />
					<Route path="privacy" element={<Privacy />} />
					<Route path="platforms" element={<Platforms />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

root.render(
	<SnackbarProvider>
		<App />
	</SnackbarProvider>
);
