const LogoLight = ({ className }) => {
	return (
		<svg
			className={className}
			width="172"
			height="36"
			viewBox="0 0 172 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M54.9964 31.8576C53.573 31.8576 52.2664 31.6826 51.0764 31.3326C49.8864 30.9592 48.813 30.4109 47.8564 29.6876C46.8997 28.9642 46.048 28.0892 45.3014 27.0626L48.2764 23.7026C49.4197 25.2892 50.5397 26.3859 51.6364 26.9926C52.733 27.5992 53.9697 27.9026 55.3464 27.9026C56.1397 27.9026 56.863 27.7859 57.5164 27.5526C58.1697 27.2959 58.683 26.9576 59.0564 26.5376C59.4297 26.0942 59.6164 25.5926 59.6164 25.0326C59.6164 24.6359 59.5347 24.2742 59.3714 23.9476C59.2314 23.5976 59.0097 23.2942 58.7064 23.0376C58.403 22.7576 58.0297 22.5009 57.5864 22.2676C57.143 22.0342 56.6414 21.8359 56.0814 21.6726C55.5214 21.5092 54.903 21.3576 54.2264 21.2176C52.943 20.9609 51.823 20.6226 50.8664 20.2026C49.9097 19.7592 49.1047 19.2226 48.4514 18.5926C47.798 17.9392 47.3197 17.2159 47.0164 16.4226C46.713 15.6059 46.5614 14.6842 46.5614 13.6576C46.5614 12.6309 46.783 11.6859 47.2264 10.8226C47.693 9.95924 48.323 9.21257 49.1164 8.58257C49.9097 7.95257 50.8314 7.46257 51.8814 7.11257C52.9314 6.76257 54.063 6.58757 55.2764 6.58757C56.653 6.58757 57.878 6.75091 58.9514 7.07757C60.048 7.40424 61.0047 7.89424 61.8214 8.54757C62.6614 9.17757 63.3497 9.94757 63.8864 10.8576L60.8764 13.8326C60.4097 13.1092 59.8847 12.5142 59.3014 12.0476C58.718 11.5576 58.088 11.1959 57.4114 10.9626C56.7347 10.7059 56.023 10.5776 55.2764 10.5776C54.4364 10.5776 53.7014 10.6942 53.0714 10.9276C52.4647 11.1609 51.9864 11.4992 51.6364 11.9426C51.2864 12.3626 51.1114 12.8759 51.1114 13.4826C51.1114 13.9492 51.2164 14.3692 51.4264 14.7426C51.6364 15.0926 51.928 15.4076 52.3014 15.6876C52.698 15.9676 53.1997 16.2126 53.8064 16.4226C54.413 16.6326 55.1014 16.8192 55.8714 16.9826C57.1547 17.2392 58.3097 17.5892 59.3364 18.0326C60.363 18.4526 61.238 18.9659 61.9614 19.5726C62.6847 20.1559 63.233 20.8326 63.6064 21.6026C63.9797 22.3492 64.1664 23.1892 64.1664 24.1226C64.1664 25.7326 63.7814 27.1209 63.0114 28.2876C62.2647 29.4309 61.203 30.3176 59.8264 30.9476C58.4497 31.5542 56.8397 31.8576 54.9964 31.8576Z"
				fill="white"
			/>
			<path
				d="M68.8197 31.5076V7.00757H73.1247L82.0497 21.5326L79.5297 21.4976L88.5597 7.00757H92.6547V31.5076H88.1397V22.3026C88.1397 20.2026 88.1864 18.3126 88.2797 16.6326C88.3964 14.9526 88.5831 13.2842 88.8397 11.6276L89.3997 13.1326L81.8047 24.8576H79.4947L72.1447 13.2376L72.6347 11.6276C72.8914 13.1909 73.0664 14.8009 73.1597 16.4576C73.2764 18.0909 73.3347 20.0392 73.3347 22.3026V31.5076H68.8197Z"
				fill="white"
			/>
			<path
				d="M106.66 31.8576C105.236 31.8576 103.93 31.6826 102.74 31.3326C101.55 30.9592 100.476 30.4109 99.5196 29.6876C98.563 28.9642 97.7113 28.0892 96.9646 27.0626L99.9396 23.7026C101.083 25.2892 102.203 26.3859 103.3 26.9926C104.396 27.5992 105.633 27.9026 107.01 27.9026C107.803 27.9026 108.526 27.7859 109.18 27.5526C109.833 27.2959 110.346 26.9576 110.72 26.5376C111.093 26.0942 111.28 25.5926 111.28 25.0326C111.28 24.6359 111.198 24.2742 111.035 23.9476C110.895 23.5976 110.673 23.2942 110.37 23.0376C110.066 22.7576 109.693 22.5009 109.25 22.2676C108.806 22.0342 108.305 21.8359 107.745 21.6726C107.185 21.5092 106.566 21.3576 105.89 21.2176C104.606 20.9609 103.486 20.6226 102.53 20.2026C101.573 19.7592 100.768 19.2226 100.115 18.5926C99.4613 17.9392 98.983 17.2159 98.6796 16.4226C98.3763 15.6059 98.2246 14.6842 98.2246 13.6576C98.2246 12.6309 98.4463 11.6859 98.8896 10.8226C99.3563 9.95924 99.9863 9.21257 100.78 8.58257C101.573 7.95257 102.495 7.46257 103.545 7.11257C104.595 6.76257 105.726 6.58757 106.94 6.58757C108.316 6.58757 109.541 6.75091 110.615 7.07757C111.711 7.40424 112.668 7.89424 113.485 8.54757C114.325 9.17757 115.013 9.94757 115.55 10.8576L112.54 13.8326C112.073 13.1092 111.548 12.5142 110.965 12.0476C110.381 11.5576 109.751 11.1959 109.075 10.9626C108.398 10.7059 107.686 10.5776 106.94 10.5776C106.1 10.5776 105.365 10.6942 104.735 10.9276C104.128 11.1609 103.65 11.4992 103.3 11.9426C102.95 12.3626 102.775 12.8759 102.775 13.4826C102.775 13.9492 102.88 14.3692 103.09 14.7426C103.3 15.0926 103.591 15.4076 103.965 15.6876C104.361 15.9676 104.863 16.2126 105.47 16.4226C106.076 16.6326 106.765 16.8192 107.535 16.9826C108.818 17.2392 109.973 17.5892 111 18.0326C112.026 18.4526 112.901 18.9659 113.625 19.5726C114.348 20.1559 114.896 20.8326 115.27 21.6026C115.643 22.3492 115.83 23.1892 115.83 24.1226C115.83 25.7326 115.445 27.1209 114.675 28.2876C113.928 29.4309 112.866 30.3176 111.49 30.9476C110.113 31.5542 108.503 31.8576 106.66 31.8576Z"
				fill="white"
			/>
			<path
				d="M126.678 31.8576C125.161 31.8576 123.785 31.4376 122.548 30.5976C121.311 29.7576 120.32 28.6142 119.573 27.1676C118.826 25.7209 118.453 24.0759 118.453 22.2326C118.453 20.3892 118.826 18.7442 119.573 17.2976C120.32 15.8509 121.335 14.7192 122.618 13.9026C123.901 13.0859 125.348 12.6776 126.958 12.6776C127.891 12.6776 128.743 12.8176 129.513 13.0976C130.283 13.3542 130.96 13.7276 131.543 14.2176C132.126 14.7076 132.605 15.2676 132.978 15.8976C133.375 16.5276 133.643 17.2042 133.783 17.9276L132.838 17.6826V13.0626H137.178V31.5076H132.803V27.0976L133.818 26.9226C133.655 27.5526 133.351 28.1709 132.908 28.7776C132.488 29.3609 131.951 29.8859 131.298 30.3526C130.668 30.7959 129.956 31.1576 129.163 31.4376C128.393 31.7176 127.565 31.8576 126.678 31.8576ZM127.868 28.0426C128.871 28.0426 129.758 27.7976 130.528 27.3076C131.298 26.8176 131.893 26.1409 132.313 25.2776C132.756 24.3909 132.978 23.3759 132.978 22.2326C132.978 21.1126 132.756 20.1209 132.313 19.2576C131.893 18.3942 131.298 17.7176 130.528 17.2276C129.758 16.7376 128.871 16.4926 127.868 16.4926C126.865 16.4926 125.978 16.7376 125.208 17.2276C124.461 17.7176 123.878 18.3942 123.458 19.2576C123.038 20.1209 122.828 21.1126 122.828 22.2326C122.828 23.3759 123.038 24.3909 123.458 25.2776C123.878 26.1409 124.461 26.8176 125.208 27.3076C125.978 27.7976 126.865 28.0426 127.868 28.0426Z"
				fill="white"
			/>
			<path
				d="M143.499 31.5076V11.4176C143.499 10.2976 143.744 9.30591 144.234 8.44257C144.724 7.55591 145.401 6.86757 146.264 6.37757C147.127 5.86424 148.131 5.60757 149.274 5.60757C150.067 5.60757 150.802 5.74757 151.479 6.02757C152.156 6.28424 152.727 6.64591 153.194 7.11257L151.864 10.3676C151.561 10.1109 151.234 9.91257 150.884 9.77257C150.557 9.63257 150.242 9.56257 149.939 9.56257C149.472 9.56257 149.076 9.64424 148.749 9.80757C148.446 9.94757 148.212 10.1692 148.049 10.4726C147.909 10.7759 147.839 11.1376 147.839 11.5576V31.5076H145.669C145.249 31.5076 144.852 31.5076 144.479 31.5076C144.129 31.5076 143.802 31.5076 143.499 31.5076ZM140.629 17.2276V13.4476H152.004V17.2276H140.629Z"
				fill="white"
			/>
			<path
				d="M163.171 31.8576C161.234 31.8576 159.519 31.4492 158.026 30.6326C156.556 29.8159 155.401 28.7076 154.561 27.3076C153.744 25.8842 153.336 24.2626 153.336 22.4426C153.336 20.9959 153.569 19.6776 154.036 18.4876C154.502 17.2976 155.144 16.2709 155.961 15.4076C156.801 14.5209 157.792 13.8442 158.936 13.3776C160.102 12.8876 161.374 12.6426 162.751 12.6426C163.964 12.6426 165.096 12.8759 166.146 13.3426C167.196 13.8092 168.106 14.4509 168.876 15.2676C169.646 16.0609 170.229 17.0176 170.626 18.1376C171.046 19.2342 171.244 20.4359 171.221 21.7426L171.186 23.2476H156.241L155.436 20.3076H167.581L167.021 20.9026V20.1326C166.951 19.4092 166.717 18.7792 166.321 18.2426C165.924 17.6826 165.411 17.2509 164.781 16.9476C164.174 16.6209 163.497 16.4576 162.751 16.4576C161.607 16.4576 160.639 16.6792 159.846 17.1226C159.076 17.5659 158.492 18.2076 158.096 19.0476C157.699 19.8642 157.501 20.8909 157.501 22.1276C157.501 23.3176 157.746 24.3559 158.236 25.2426C158.749 26.1292 159.461 26.8176 160.371 27.3076C161.304 27.7742 162.389 28.0076 163.626 28.0076C164.489 28.0076 165.282 27.8676 166.006 27.5876C166.729 27.3076 167.511 26.8059 168.351 26.0826L170.486 29.0576C169.856 29.6409 169.132 30.1426 168.316 30.5626C167.522 30.9592 166.682 31.2742 165.796 31.5076C164.909 31.7409 164.034 31.8576 163.171 31.8576Z"
				fill="white"
			/>
			<path
				d="M17.8015 35.5C27.4665 35.5 35.3015 27.665 35.3015 18C35.3015 8.33504 27.4665 0.5 17.8015 0.5C8.13655 0.5 0.301514 8.33504 0.301514 18C0.301514 21.6154 1.3979 24.9748 3.27634 27.7638L1.64767 35.5L10.1028 33.72C12.4261 34.8599 15.039 35.5 17.8015 35.5Z"
				fill="url(#paint0_linear_21_925)"
			/>
			<path
				d="M17.8011 28.0087C12.2736 28.0087 7.79257 23.5277 7.79257 18.0001C7.79257 12.4726 12.2736 7.99161 17.8011 7.99161C23.3286 7.99161 27.8096 12.4726 27.8096 18.0001C27.8096 20.0679 27.1826 21.9892 26.1083 23.5842L27.0397 28.0086L22.2041 26.9906C20.8754 27.6426 19.381 28.0087 17.8011 28.0087Z"
				fill="white"
			/>
			<path
				d="M19.9993 16.079C19.9993 14.8649 19.0164 13.8792 17.8024 13.8792C16.5883 13.8792 15.6054 14.8649 15.6054 16.079C15.6054 16.8865 16.0453 17.5854 16.6941 17.9669L15.6054 22.1213H19.9993L18.9106 17.9669C19.5594 17.5854 19.9993 16.8865 19.9993 16.079Z"
				fill="url(#paint1_linear_21_925)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_21_925"
					x1="17.8015"
					y1="0.5"
					x2="17.8015"
					y2="35.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#4E96F7" />
					<stop offset="1" stopColor="#3C57CC" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_21_925"
					x1="17.8024"
					y1="13.8792"
					x2="17.8024"
					y2="22.1213"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#4E96F7" />
					<stop offset="1" stopColor="#3C57CC" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default LogoLight;
