import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import twilio from "../assets/platforms/twilio.jpeg";
import google from "../assets/platforms/google.webp";
import facebook from "../assets/platforms/facebook.avif";
import x from "../assets/platforms/x.avif";
import youtube from "../assets/platforms/youtube.webp";
import tiktok from "../assets/platforms/tiktok.jpeg";
import binance from "../assets/platforms/binance.jpeg";
import adobe from "../assets/platforms/adobe.png";
import shopify from "../assets/platforms/shopify.jpeg";
import wix from "../assets/platforms/wix.png";
import braintree from "../assets/platforms/braintree.webp";
import stripe from "../assets/platforms/stripe.png";

const PlatformPreview = () => {
	return (
		<div className="platform-preview">
			<Container>
				<Row>
					<Col className="col-12">
						<h2 className="platforms-title title-xl text-regular-lexend mb-50 align-center">
							Supported Platforms
						</h2>
					</Col>
				</Row>
				<Row className="g-md-4 g-2">
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={google} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={facebook} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={x} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={youtube} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={tiktok} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={twilio} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={binance} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={adobe} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={shopify} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={wix} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={braintree} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="platform-preview-item flex-column rounded-4 border">
							<img src={stripe} alt="" className="platform-preview-logo" />
						</Link>
					</Col>
					<div className="flex-row-center mt-5">
						<Link to="/platforms">
							<Button className="platform-preview-btn btn-default btn-lg text-md rounded-4 ps-5 pe-5">
								See More
							</Button>
						</Link>
					</div>
				</Row>
			</Container>
		</div>
	);
};

export default PlatformPreview;
