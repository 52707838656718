import { useState } from "react";
import { Outlet, Link, NavLink, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import LogoLight from "../components/LogoLight";
import LogoDark from "../components/LogoDark";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

const Layout = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const isIndexPage = location.pathname === "/";
	const [showNav, setShowNav] = useState(false);

	return (
		<>
			<div
				className={`main-nav flex-row-left ${
					isIndexPage ? "bg-default" : "bg-white"
				}`}
			>
				<Container>
					<Row className="flex-row-left">
						<Col>
							<Link to="/" className="nav-brand">
								{isIndexPage ? (
									<>
										<LogoLight className="nav-logo" />
										<span className="beta text-sm text-medium-inter text-white ms-2">
											BETA
										</span>
									</>
								) : (
									<>
										<LogoDark className="nav-logo" />
										<span className="beta text-sm text-medium-inter ms-2">
											BETA
										</span>
									</>
								)}
							</Link>
						</Col>
						<Col className="col-6 nav-lg flex-row-center">
							<Nav.Link
								href="/features/"
								className={`main-nav-item ${isIndexPage && "text-white"}`}
							>
								Features
							</Nav.Link>
							<Nav.Link
								href="/pricing/"
								className={`main-nav-item ${isIndexPage && "text-white"}`}
							>
								Pricing
							</Nav.Link>
							<Nav.Link
								href="/faq/"
								className={`main-nav-item ${isIndexPage && "text-white"}`}
							>
								FAQ
							</Nav.Link>
							<Nav.Link
								href="/contact/"
								className={`main-nav-item ${isIndexPage && "text-white"}`}
							>
								Contact Us
							</Nav.Link>
						</Col>
						<Col>
							<div className="nav-lg flex-row-right">
								<Nav.Link
									href="https://dashboard.smsafe.app/login"
									className={`main-nav-item ${isIndexPage && "text-white"}`}
								>
									Log in
								</Nav.Link>
								<Nav.Link
									href="https://dashboard.smsafe.app/signup"
									className="main-nav-item"
								>
									<Button className="btn-md btn-default text-bold-inter text-sm rounded">
										Sign Up
									</Button>
								</Nav.Link>
							</div>
							<div className="flex-row-right full">
								<div
									className="nav-toggle flex-column"
									onClick={() => setShowNav(!showNav)}
								>
									<div className={`fries ${showNav ? "active" : ""}`}>
										<div
											className={`top-fry ${
												isIndexPage ? "bg-white" : "bg-default"
											}`}
										/>
										<div
											className={`mid-fry ${
												isIndexPage ? "bg-white" : "bg-default"
											}`}
										/>
										<div
											className={`bottom-fry ${
												isIndexPage ? "bg-white" : "bg-default"
											}`}
										/>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className={`nav-sm bg-white border-top ${showNav && "active"}`}>
				<Container className="full-height">
					<NavLink
						to="/"
						className="nav-sm-item text-medium-inter block mb-20"
						onClick={() => setShowNav(false)}
					>
						<span className="text-lg">Home</span>
					</NavLink>
					<NavLink
						to="/features"
						className="nav-sm-item text-medium-inter block mb-20"
						onClick={() => setShowNav(false)}
					>
						<span className="text-lg">Features</span>
					</NavLink>
					<NavLink
						to="/pricing"
						className="nav-sm-item text-medium-inter block mb-20"
						onClick={() => setShowNav(false)}
					>
						<span className="text-lg">Pricing</span>
					</NavLink>
					<NavLink
						to="/faq"
						className="nav-sm-item text-medium-inter block mb-20"
						onClick={() => setShowNav(false)}
					>
						<span className="text-lg">FAQ</span>
					</NavLink>
					<NavLink
						to="/contact"
						className="nav-sm-item text-medium-inter block mb-20"
						onClick={() => setShowNav(false)}
					>
						<span className="text-lg">Contact Us</span>
					</NavLink>
					<div className="nav-sm-action full">
						<Container>
							<a href="https://dashboard.smsafe.app/login">
								<Button className="btn-md btn-gray-dark text-medium-inter text-default full mb-15">
									Log In
								</Button>
							</a>
							<a href="https://dashboard.smsafe.app/signup">
								<Button
									className="btn-md btn-default text-medium-inter full mb-15"
									onClick={() => {
										setShowNav(false);
									}}
								>
									Sign Up
								</Button>
							</a>
						</Container>
					</div>
				</Container>
			</div>
			<Outlet />
			<Footer />
		</>
	);
};

export default Layout;
