import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SubFeature from "../components/SubFeature";
import FaqPreview from "../components/FaqPreview";
import Cta from "../components/Cta";
import ContactCta from "../components/ContactCta";

const Features = () => {
	return (
		<div className="content">
			<div className="page-banner">
				<Container className="flex-column align-center">
					<h1 className="banner-title title-xxl text-regular-lexend mb-30">
						Every Secure Feature You Need
					</h1>
					<p className="banner-desc text-lg opacity-7 mb-50">
						Discover how SMSafe's comprehensive suite of features can ensure
						your online security and peace of mind.
					</p>
					<a href="https://dashboard.smsafe.app/signup" className="mobile-full">
						<Button className="btn-wide btn-lg btn-default text-md mb-15 rounded-4">
							Get Started
						</Button>
					</a>
					<span className="opacity-5">
						or{" "}
						<a href="/pricing" className="text-medium-inter see-pricing">
							See Pricing
						</a>
					</span>
				</Container>
			</div>
			<div className="feature-page-content">
				<Container>
					<div className="feature-page-item bg-gray mb-50">
						<Row>
							<Col className="col-lg-6 col-12">
								<h2 className="feature-title title-xl text-regular-lexend mb-15">
									Virtual Phone Number Provision
								</h2>
								<p className="feature-page-desc text-lg opacity-5 mb-0">
									Get Your Dedicated Phone Number for 2FA
								</p>
							</Col>
							<Col className="col-lg-6 col-12">
								<SubFeature
									className="mb-30"
									content="Dedicated phone number for 2FA purposes"
								/>
								<SubFeature
									className="mb-30"
									content="Choice of local or international phone numbers"
								/>
								<SubFeature
									className="mb-30"
									content="Multiple phone number options for different services/accounts"
								/>
								<SubFeature
									className="mb-0"
									content="Seamless integration with popular authentication apps"
								/>
							</Col>
						</Row>
					</div>
					<div className="feature-page-item bg-gray mb-50">
						<Row>
							<Col className="col-lg-6 col-12">
								<h2 className="feature-title title-xl text-regular-lexend mb-15">
									2FA Code Management
								</h2>
								<p className="feature-page-desc text-lg opacity-5 mb-0">
									Efficient Management of Your 2FA Codes
								</p>
							</Col>
							<Col className="col-lg-6 col-12">
								<SubFeature
									className="mb-30"
									content="Centralized storage and management of 2FA codes"
								/>
								<SubFeature
									className="mb-30"
									content="Support for time-based OTP codes and QR code scanning"
								/>
								<SubFeature
									className="mb-30"
									content="Customizable account labels and organization for easy access"
								/>
								<SubFeature
									className="mb-30"
									content="Backup and restore functionality across devices"
								/>
								<SubFeature
									className="mb-0"
									content="Push notifications for quick code retrieval"
								/>
							</Col>
						</Row>
					</div>
					<div className="feature-page-item bg-gray mb-50">
						<Row>
							<Col className="col-lg-6 col-12">
								<h2 className="feature-title title-xl text-regular-lexend mb-15">
									Secure Authentication
								</h2>
								<p className="feature-page-desc text-lg opacity-5 mb-0">
									Enhanced Security for Your Accounts
								</p>
							</Col>
							<Col className="col-lg-6 col-12">
								<SubFeature
									className="mb-30"
									content="Two-factor authentication for enhanced account security"
								/>
								<SubFeature
									className="mb-30"
									content="Protection against unauthorized access and identity theft"
								/>
								<SubFeature
									className="mb-30"
									content="Secure transmission of authentication codes"
								/>
								<SubFeature
									className="mb-30"
									content="Encrypted storage of 2FA data"
								/>
								<SubFeature
									className="mb-0"
									content="Additional security measures like biometric authentication (fingerprint/face ID)"
								/>
							</Col>
						</Row>
					</div>
					<div className="feature-page-item bg-gray mb-50">
						<Row>
							<Col className="col-lg-6 col-12">
								<h2 className="feature-title title-xl text-regular-lexend mb-15">
									Verification Services
								</h2>
								<p className="feature-page-desc text-lg opacity-5 mb-0">
									Trustworthy Sender Verification
								</p>
							</Col>
							<Col className="col-lg-6 col-12">
								<SubFeature
									className="mb-30"
									content="Verification of service providers' authenticity and trustworthiness"
								/>
								<SubFeature
									className="mb-30"
									content="Protection against phishing and spoofing attempts"
								/>
								<SubFeature
									className="mb-30"
									content="Secure verification of transactions and online interactions"
								/>
								<SubFeature
									className="mb-0"
									content="SMS-based verification codes for trusted interactions"
								/>
							</Col>
						</Row>
					</div>
					<div className="feature-page-item bg-gray mb-50">
						<Row>
							<Col className="col-lg-6 col-12">
								<h2 className="feature-title title-xl text-regular-lexend mb-15">
									Multi-Platform Support
								</h2>
								<p className="feature-page-desc text-lg opacity-5 mb-0">
									Seamless Integration Across Platforms
								</p>
							</Col>
							<Col className="col-lg-6 col-12">
								<SubFeature
									className="mb-30"
									content="Compatibility with popular online platforms and services"
								/>
								<SubFeature
									className="mb-30"
									content="Seamless integration with social media, email, banking, and more"
								/>
								<SubFeature
									className="mb-0"
									content="Support for multiple devices (mobile and desktop)"
								/>
							</Col>
						</Row>
					</div>
					<div className="feature-page-item bg-gray mb-50">
						<Row>
							<Col className="col-lg-6 col-12">
								<h2 className="feature-title title-xl text-regular-lexend mb-15">
									Account Recovery
								</h2>
								<p className="feature-page-desc text-lg opacity-5 mb-0">
									Reliable Account Recovery Options
								</p>
							</Col>
							<Col className="col-lg-6 col-12">
								<SubFeature
									className="mb-30"
									content="Account recovery options in case of device loss or change"
								/>
								<SubFeature
									className="mb-30"
									content="Backup and restore functionality for seamless transition"
								/>
								<SubFeature
									className="mb-0"
									content="Account verification through alternate contact methods"
								/>
							</Col>
						</Row>
					</div>
					<div className="feature-page-item bg-gray mb-50">
						<Row>
							<Col className="col-lg-6 col-12">
								<h2 className="feature-title title-xl text-regular-lexend mb-15">
									Privacy and Data Security
								</h2>
								<p className="feature-page-desc text-lg opacity-5 mb-0">
									Robust Privacy and Data Protection
								</p>
							</Col>
							<Col className="col-lg-6 col-12">
								<SubFeature
									className="mb-30"
									content="Protection of personal information and user data"
								/>
								<SubFeature
									className="mb-30"
									content="Compliance with privacy regulations and best practices"
								/>
								<SubFeature
									className="mb-0"
									content="Transparent data handling and privacy policies"
								/>
							</Col>
						</Row>
					</div>
					<div className="feature-page-item bg-gray mb-50">
						<Row>
							<Col className="col-lg-6 col-12">
								<h2 className="feature-title title-xl text-regular-lexend mb-15">
									Customer Support
								</h2>
								<p className="feature-page-desc text-lg opacity-5 mb-0">
									Dedicated Support for Your Needs
								</p>
							</Col>
							<Col className="col-lg-6 col-12">
								<SubFeature
									className="mb-30"
									content="Responsive customer support via email or online chat"
								/>
								<SubFeature
									className="mb-30"
									content="Knowledge base and FAQ section for self-help"
								/>
								<SubFeature
									className="mb-0"
									content="Community forums for user discussions and assistance"
								/>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
			<FaqPreview />
			<Cta />
			<ContactCta />
		</div>
	);
};

export default Features;
