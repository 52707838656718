import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const ContactCta = () => {
	return (
		<div className="contact-cta-container">
			<Container>
				<div className="contact-cta p-50 bg-default">
					<Row className="flex-row-left">
						<Col className="contact-cta-text col-lg-5 col-md-6 col-12">
							<h3 className="contact-cta-title title-md text-white text-regular-lexend">
								Curious? Reach Out to Us for Answers and Assistance!
							</h3>
						</Col>
						<Col className="contact-cta-btn col-lg-7 col-md-6 flex-row-right col-12">
							<Link to="/contact">
								<Button className="btn-wide btn-default btn-lg text-md rounded-4">
									Contact Us
								</Button>
							</Link>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	);
};

export default ContactCta;
