import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

const FaqPreview = () => {
	return (
		<div className="faq-preview-container">
			<Container>
				<Row className="flex-row-left">
					<Col className="col-md-5 col-12 mb-30">
						<h2 className="faq-title title-xl text-regular-lexend">
							Frequently Asked Questions
						</h2>
					</Col>
					<Col className="col-md-7 col-12">
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header className="faq-question text-lg opacity-5">
									How does SMSafe ensure the privacy of my personal information?
								</Accordion.Header>
								<Accordion.Body className="text-sm">
									SMSafe prioritizes the security and privacy of your personal
									information. We employ industry-standard security measures,
									including encryption, secure data storage, and access
									controls, to protect against unauthorized access, disclosure,
									alteration, and destruction of your data. Additionally, SMSafe
									is designed to adhere to strict privacy guidelines outlined in
									our{" "}
									<a href="/privacy" className="decorated">
										Privacy and Terms of Service Statement
									</a>
									, providing you with a secure environment for your personal
									information.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header className="faq-question text-lg opacity-5">
									Can I use SMSafe for both personal and business accounts?
								</Accordion.Header>
								<Accordion.Body className="text-sm">
									Yes, SMSafe is designed to be versatile and can be used for
									both personal and business accounts. Whether you're securing
									your personal online presence or implementing two-factor
									authentication for your business, SMSafe provides a reliable
									and secure solution for a wide range of use cases.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header className="faq-question text-lg opacity-5">
									Are the virtual phone numbers provided by SMSafe globally
									accessible?
								</Accordion.Header>
								<Accordion.Body className="text-sm">
									No, virtual phone numbers provided by SMSafe are specific to
									the country of origin, as determined by their country code.
									They can only be used within the same country as the assigned
									country code.
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default FaqPreview;
