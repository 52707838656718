import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SubFeature from "../components/SubFeature";
import ContactCta from "../components/ContactCta";

const Pricing = () => {
	const [country, setCountry] = useState("US");
	const [loading, setLoading] = useState(true);

	const currencies = {
		US: {
			symbol: "USD",
			basic: 6,
			premium: 18,
		},
		CA: {
			symbol: "CAD",
			basic: 8.45,
			premium: 24.45,
		},
		GB: {
			symbol: "GBP",
			basic: 4.95,
			premium: 14.45,
		},
	};

	const getCountry = async () => {
		setLoading(true);
		const requestOptions = { method: "GET" };

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/country-code`,
			requestOptions
		);
		const data = await response.json();

		if (response.ok) {
			if (data.country_code in currencies) {
				setCountry(data.country_code);
			} else {
				setCountry("US");
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		getCountry();
	}, []);

	return (
		<div className="content pricing-content">
			<div className="pricing-banner page-banner">
				<Container>
					<div className="flex-column mb-50">
						<h1 className="pricing-title title-lg text-bold-lexend align-center mb-20">
							Choose the plan that's right for you.
						</h1>
						<p className="banner-desc align-center opacity-7 mb-40">
							Pick the plan that meets your organization's needs, or{" "}
							<a href="/contact" className="text-bold-inter">
								contact sales
							</a>{" "}
							for assistance.
						</p>
						<div className="fit">
							<Form.Select
								className="pricing-currency"
								value={country}
								onChange={(e) => setCountry(e.target.value)}
								disabled={loading}
							>
								<option value="US">USD</option>
								<option value="CA">CAD</option>
								<option value="GB">GBP</option>
							</Form.Select>
						</div>
					</div>
					<Row>
						<Col className="col-lg-3 col-md-6 col-12 mb-30">
							<div className="pricing-tier rounded-8 full bg-white p-20">
								<div className="mb-20">
									<h2 className="text-xl text-medium-lexend mb-0">Free</h2>
									<span className="text-medium-inter">
										{currencies[country]?.symbol} 0/month
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">1 safe</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Unlimited 2FA storage
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">1 member per safe</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Basic email support</span>
								</div>
								<div className="tier-btn-container full p-20">
									<a href="https://dashboard.smsafe.app/signup">
										<Button
											variant="outline-secondary"
											className="btn-md text-sm full rounded-4 upper text-medium-inter"
										>
											Get Started
										</Button>
									</a>
								</div>
							</div>
						</Col>
						<Col className="col-lg-3 col-md-6 col-12 mb-30">
							<div className="pricing-tier rounded-8 full bg-white p-20">
								<div className="mb-20">
									<h2 className="text-xl text-medium-lexend mb-0">Basic</h2>
									<span className="text-medium-inter">
										{currencies[country]?.symbol} {currencies[country]?.basic}
										/month
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Max. 4 safes</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Unlimited 2FA storage
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Max. 4 members per safe
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										1 Free virtual phone number
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Max. 2 virtual phone numbers*
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">SMS forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Email forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Basic support</span>
								</div>
								<div className="tier-btn-container full p-20">
									<a href="https://dashboard.smsafe.app/signup">
										<Button className="btn-md btn-default full rounded-4 text-sm upper text-medium-inter">
											Get Started
										</Button>
									</a>
								</div>
							</div>
						</Col>
						<Col className="col-lg-3 col-md-6 col-12 mb-30">
							<div className="pricing-tier rounded-8 full bg-white p-20">
								<div className="mb-20">
									<h2 className="text-xl text-medium-lexend mb-0">Premium</h2>
									<span className="text-medium-inter">
										{currencies[country]?.symbol} {currencies[country]?.premium}
										/month
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Unlimited safes</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Unlimited 2FA storage
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Max. 10 members per safe
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										1 Free virtual phone number
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">
										Max. 10 virtual phone numbers*
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">SMS forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Email forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Webhook forwarding</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-blue text-lg me-2" />
									<span className="text-sm opacity-7">Priority support</span>
								</div>
								<div className="tier-btn-container full p-20">
									<a href="https://dashboard.smsafe.app/signup">
										<Button className="btn-md btn-default full rounded-4 text-sm upper text-medium-inter">
											Get Started
										</Button>
									</a>
								</div>
							</div>
						</Col>
						<Col className="col-lg-3 col-md-6 col-12 mb-30">
							<div className="pricing-tier rounded-8 full bg-default text-white p-20">
								<div className="mb-20">
									<h2 className="text-xl text-medium-lexend mb-0">
										Enterprise
									</h2>
									<span className="text-medium-inter">Contact Sales</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">Tailored solutions</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										Dedicated account manager
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										Unlimited virtual phone numbers
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										End-to-end encryption
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										Additional access controls
									</span>
								</div>
								<div className="flex-row-left mb-10">
									<i className="ri-check-line text-white text-lg me-2" />
									<span className="text-sm opacity-7">
										Service Level Agreement
									</span>
								</div>
								<div className="tier-btn-container full p-20">
									<a href="/contact">
										<Button className="btn-md btn-default full rounded-4 text-sm upper text-medium-inter">
											Contact Sales
										</Button>
									</a>
								</div>
							</div>
						</Col>
					</Row>
					{/* <h1 className="title-xl text-regular-lexend mb-30 opacity-7">
						Price
					</h1>
					<span className="pricing-value text-regular-lexend mb-20">
						$10.00
					</span>
					<span className="text-xl opacity-5 mb-30">per month</span>
					<a href="https://dashboard.smsafe.app/signup">
						<Button className="cta-btn btn-default text-xl mb-30">
							Get Started
						</Button>
					</a>
					<span className="opacity-5">
						Renews Automatically. Cancel Anytime.
					</span> */}
				</Container>
			</div>
			<div className="pricing-inclusions">
				<Container>
					<Row>
						<Col className="col-lg-5 col-12">
							<h2 className="pricing-inclusion-title title-xl text-regular-lexend">
								What's included?
							</h2>
						</Col>
						<Col className="col-lg-7 col-12">
							<SubFeature
								className="mb-20"
								content="Choice of local or international phone numbers"
							/>
							<SubFeature
								className="mb-20"
								content="Multiple phone number options for different services/accounts"
							/>
							<SubFeature
								className="mb-20"
								content="Dedicated phone number for 2FA purposes"
							/>
						</Col>
					</Row>
				</Container>
			</div>
			<ContactCta />
		</div>
	);
};

export default Pricing;
