import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import twilio from "../assets/platforms/twilio.jpeg";
import google from "../assets/platforms/google.webp";
import facebook from "../assets/platforms/facebook.avif";
import x from "../assets/platforms/x.avif";
import lastpass from "../assets/platforms/lastpass.png";
import bitwarden from "../assets/platforms/bitwarden.png";
import passwords from "../assets/platforms/passwords.jpg";
import onepassword from "../assets/platforms/onepassword.webp";

const Transition = () => {
	return (
		<div className="transition-preview bg-blue">
			<Container>
				<Row>
					<Col className="col-12">
						<h2 className="platforms-title title-xl text-regular-lexend mb-20 align-center text-white">
							Seamlessly Transition to SMSafe
						</h2>
						<p className="text-lg opacity-7 align-center mb-50 text-white">
							Import Your Passwords from Other Providers in Just a Few Clicks
						</p>
					</Col>
				</Row>
				<Row className="g-md-4 g-2">
					<Col className="col-md-3 col-6">
						<Link className="password-preview-item flex-column rounded-4">
							<img src={lastpass} alt="" className="password-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="password-preview-item flex-column rounded-4">
							<img src={bitwarden} alt="" className="password-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="password-preview-item flex-column rounded-4">
							<img src={passwords} alt="" className="password-preview-logo" />
						</Link>
					</Col>
					<Col className="col-md-3 col-6">
						<Link className="password-preview-item flex-column rounded-4">
							<img src={onepassword} alt="" className="password-preview-logo" />
						</Link>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Transition;
