import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FeatureCheck from "./FeatureCheck";

const SubFeature = ({ content, className }) => {
	return (
		<Row className={`sub-feature mb-15 ${className}`}>
			<Col className="sub-feature-check">
				<FeatureCheck />
			</Col>
			<Col>
				<p className="sub-feature-text text-lg opacity-7 mb-0">{content}</p>
			</Col>
		</Row>
	);
};

export default SubFeature;
