const FeatureCheck = () => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			className="feature-check"
		>
			<g clipPath="url(#clip0_46_1185)">
				<path
					d="M15 27.5C8.09625 27.5 2.5 21.9037 2.5 15C2.5 8.09625 8.09625 2.5 15 2.5C21.9037 2.5 27.5 8.09625 27.5 15C27.5 21.9037 21.9037 27.5 15 27.5ZM13.7537 20L22.5912 11.1613L20.8237 9.39375L13.7537 16.465L10.2175 12.9288L8.45 14.6962L13.7537 20Z"
					fill="#3C57CC"
				/>
			</g>
			<defs>
				<clipPath id="clip0_46_1185">
					<rect width="30" height="30" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default FeatureCheck;
